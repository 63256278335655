import axios from 'axios'
import { Toast } from 'vant'
import router from '@/router/index.js'

const service = axios.create({
  baseURL: process.env.VUE_APP_CARDBAG_API
})

// request拦截器
service.interceptors.request.use(
  config => {
    config.headers = {
      'Content-Type': 'application/json'
    }
    // 广发token
    const guangfaToken = localStorage.getItem('guangfaToken')
    // 旧平台卡包token
    const cardbagToken = localStorage.getItem('cardbagToken')
    if (config.url.indexOf('guangfaBank') !== -1 && guangfaToken) {
      config.headers.Authorization = guangfaToken
    } else if (config.url.indexOf('cardBag') !== -1 && cardbagToken) {
      config.headers.cardbagtoken = cardbagToken
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code === 0) {
      return response.data
    } else {
      Toast.fail(res.message || 'error')
      // return Promise.reject(new Error(res.message || 'Error'))
    }
  },
  error => {
    if (error.response.status === 401) {
      // 接口拦截器如果是汕头非标项目跳转汕头登录路由
      const nonstandardService = error.config.url.includes('shantouOrder')
      if (nonstandardService) {
        router.replace({ path: '/shantou/login' })
      }
      const nonguangfaService = error.config.url.includes('guangfaBank')
      if (nonguangfaService) {
        router.replace({ path: '/guangfa/login' })
      }
      Toast.fail('登录超时')
      return Promise.reject(error)
    } else {
      Toast.fail('服务器异常，请稍后重试')
      return Promise.reject(error)
    }
  }
)

export default service
